import {Navigate} from 'react-router-dom';
import AuthService from '../../services/authService';
import React from 'react';
import './overview.css';


const authService = new AuthService();

function Overview() {
  const token = authService.getToken();

  if (!token) {
    return <Navigate to="../login" replace/>;
  }

  return (
    <div>
      <div>
        <a
          href="admin/members"
          type="button"
          className="admin-overview-button btn bg-hoiboerzu-dark-One text-color-hoiboerzu-bright">Members bearbeiten</a>
      </div>

      <div>
        <a
          href="admin/events"
          type="button"
          className="admin-overview-button btn bg-hoiboerzu-dark-One text-color-hoiboerzu-bright">Events bearbeiten</a>
      </div>

      <div>
        <a
          href="admin/president"
          type="button"
          className="admin-overview-button btn bg-hoiboerzu-dark-One text-color-hoiboerzu-bright">Präsident bearbeiten</a>
      </div>

    </div>
  );
}

export default Overview;
