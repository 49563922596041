const url = `${process.env.REACT_APP_API_CONNECTION_ROOT}formerpresidents`;

export default class FormerPresidentsService {
  getFormerPresidents() {
    return fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
  }
}
