import React from 'react';
import './loadingScreen.css';
import logo from '../../logo.png';

class LoadingScreen extends React.Component {
  render() {
    const {active} = this.props;
    if (!active) {
      return null;
    }

    return (
      <div className="loading-screen">
        <img src={logo} alt='Loading...' className="loading-spinner"></img>
        <label>Das Laden der Daten kann aufgrund des kostenfreien Hostings bis zu einer Minute dauern</label>
      </div>
    );
  }
}

export default LoadingScreen;
