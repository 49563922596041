import AuthService from './authService';

const url = `${process.env.REACT_APP_API_CONNECTION_ROOT}events`;

const authService = new AuthService();

export default class EventService {
  getAllEvents() {
    return fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
  }

  createEvent(event) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authService.getToken()}`,
      },
      body: JSON.stringify({event: event}),
    };
    return fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
  }

  updateEvent(event) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authService.getToken()}`,
      }, body: JSON.stringify({event: event}),
    };
    return fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
  }

  deleteMember(eventId) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${authService.getToken()}`,
      },
    };
    return fetch(`${url}/${eventId}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
  }
}


