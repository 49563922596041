import {Navigate, useNavigate} from 'react-router-dom';
import React, {useState} from 'react';
import AuthService from '../../services/authService';

const authService = new AuthService();

export default function Login() {
  const token = authService.getToken();

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();

  if (token) {
    return <Navigate to="../admin" replace/>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.loginUser({
        username,
        password,
      });
      navigate('../admin');
    } catch (ex) {
      setError(ex.message);
    }
  };

  return (
    <div>
      <h1 className="mb-5">Bitte logge dich ein</h1>
      <form onSubmit={handleSubmit}>
        <div className='table'>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <p>Benutzername</p></div>
            <div className='col-12 col-sm-6'>
              <input className="w-100 rounded" type="text" onChange={(e) => setUserName(e.target.value)}/>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <p>Passwort</p></div>
            <div className='col-12 col-sm-6'>
              <input className="w-100 rounded" type="password" onChange={(e) => setPassword(e.target.value)}/>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <button className="btn bg-hoiboerzu-dark-One text-color-hoiboerzu-bright mt-4 w-100"
                type="submit">Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className='hoiboerzu-error'>{error}</div>
    </div>
  );
}
