import React from 'react';
import '../../cards/cards.css';

function CardWithPictureRight(props) {
  return (
    <div className='card-grid card-shadow bg-hoiboerzu-dark-One rounded p-4 mb-4'>
      <div className='grid-1'>
        <div>
          <h1>{props.cardTitle}</h1>
        </div>
        <div>
          {props.cardDescription}
        </div>
      </div>

      <div className='card-picture'>
        {props.picture &&
                    <img src={`data:image/png;base64,${props.picture}`} className='card-shadow card-image rounded'
                      alt='Member Picture'/>}
      </div>
    </div>
  );
}

export default CardWithPictureRight;
