import React from 'react';
import PictureSlider from '../pictureSlider/pictureSlider';
import Separator from '../separator/separator';
import DateService from '../../services/dateService';
import './event.css';

const dateService = new DateService();

function Event(props) {
  return (
    <div>
      <div>
        <h2 className='mb-0'>{props.title}</h2>
        <div className='hoiboerzu-small-font text-right mb-3'>{dateService.getFormattedDate(props.date)}</div>
        <div className='mb-4'>{props.description}</div>

        {
          props.pictures && props.pictures.length > 0 ? (
            <div className="event-container-style">
              <PictureSlider pictures={props.pictures}></PictureSlider>
            </div>

          ) : (<></>)}

        <Separator/>
      </div>
    </div>
  );
}

export default Event;
