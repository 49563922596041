import React, {useEffect, useState} from 'react';
import CardWithPictureRight from '../../components/cards/cardWithPictureRight/cardWithPictureRight';
import CountdownTimer from '../../components/countdowntimer/countdowntimer';
import FormerPresidentsService from '../../services/formerPresidentsService';
import PresidentService from '../../services/presidentService';
import LoadingScreen from '../../components/loadingScreen/loadingsScreen';
import GvDateService from '../../services/gvDateService';

const formerPresidentsService = new FormerPresidentsService();
const presidentService = new PresidentService();
const gvDateService = new GvDateService();

function Praesidenten() {
  const [loading, setLoading] = useState(false);
  const [president, setPresident] = useState({});
  const [formerPresidents, setFormerPresidents] = useState([]);
  const [gvDate, setGvDate] = useState();

  useEffect(() => {
    setLoading(true);
    formerPresidentsService.getFormerPresidents().then((data) => {
      setFormerPresidents(data.formerPresidents);
      console.log(formerPresidents);
      setLoading(false);
    });

    presidentService.getPresident().then((data) => {
      setPresident(data.president);
      console.log(president);
      setLoading(false);
    });

    gvDateService.getGvDate().then((data) => {
      const date = new Date(data.gvDate);
      setGvDate(date);
    });
  }, []);

  return (
    <>
      <LoadingScreen active={loading}></LoadingScreen>

      <div className='page-container'>
        <h1 className="mb-5">Präsidentenübersicht</h1>
        <h3 className='mb-4'>Der Hoibörzuclub wählt seine Präsidenten traditionsgemäss mit einem
          Spiel &quot;Hose-Abe&quot;.
          In den vergangenen Jahren hat sich eine Reihe von Präsidenten ergeben, die hier
          aufgelistet sind.
        </h3>

        {gvDate && (<p>
          Die Nächste Generalversammlung, bei der der neue Präsident gewählt wird findet statt in:
          <CountdownTimer targetDate={gvDate}/>
        </p>
        )}


        <div className="mt-5">
          <h3 className="">Aktueller Präsident</h3>
          <CardWithPictureRight cardTitle={president.title} cardDescription={president.description}
            picture={president.picture}></CardWithPictureRight>
        </div>

        <div className="mt-5">
          <h3>Ehemalige Präsidenten</h3>
          {formerPresidents && formerPresidents.length > 0 ? (
            formerPresidents.map((item, index) => {
              return (
                <div className="hoiboerzu-underline row" key={index}>
                  <div className="col text-start">{item.year}</div>
                  <div className="col text-start">{item.title}</div>
                </div>
              );
            })
          ) : (<p>Keine Mitglieder gefunden</p>)}
        </div>

      </div>
    </>
  );
}

export default Praesidenten;
