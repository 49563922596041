import AuthService from './authService';

const url = `${process.env.REACT_APP_API_CONNECTION_ROOT}members`;

const authService = new AuthService();

export default class MemberService {
  getAllMembers() {
    return fetch(url, {method: 'GET'})
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
  }

  createMember(member) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authService.getToken()}`,
      },
      body: JSON.stringify({member}),
    };

    return fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
  }

  updateMember(member) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authService.getToken()}`,
      }, body: JSON.stringify({member}),
    };
    return fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
  }

  deleteMember(memberId) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${authService.getToken()}`,
      },
    };
    return fetch(`${url}/${memberId}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
  }
}
