export default class DateService {
  /**
   * @param {string} inputDate must be of format: 2023-06-16T22:00:00.000Z
   * @return {string} value with format: 16.6.2023
   */
  getFormattedDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  getFormattedDateForDatepicker(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;
  }
}
