const url = `${process.env.REACT_APP_API_CONNECTION_ROOT}login`;


class AuthService {
  getToken() {
    return this.getCookie('token');
  }

  setToken(token) {
    const expirationDate=new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    document.cookie = `token=${token};expires="${expirationDate.toUTCString()}";path=/`;
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  async loginUser(credentials) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data.message;
        throw new Error(errorMessage);
      }

      this.setToken(data.token);
      return data.token;
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  }
}

export default AuthService;


