import React, {useEffect, useState} from 'react';

import PictureTransformService from '../../services/pictureTransformService';
import AuthService from '../../services/authService';
import EventService from '../../services/eventService';
import {Navigate} from 'react-router-dom';
import {FaEdit, FaTrash} from 'react-icons/fa';
import DateService from '../../services/dateService';
import {Button, Form, Modal} from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';
import './adminEvents.css';
import Separator from '../../components/separator/separator';

const eventService = new EventService();
const pictureTransformService = new PictureTransformService();
const authService = new AuthService();
const dateService = new DateService();

function AdminEvents() {
  const token = authService.getToken();

  const [events, setEvents] = useState({});
  useEffect(() => {
    eventService.getAllEvents().then((data) => {
      const sortedEvents = data.events.sort((a, b) => new Date(b.date) - new Date(a.date));
      setEvents(sortedEvents);
    });
  }, []);

  const [isModalShown, setIsModalShown] = useState(false);
  const [modalEvent, setModalEvent] = useState({
    id: '',
    title: '',
    description: '',
    date: '',
    pictures: [],
  });

  if (!token) {
    return <Navigate to="../../login" replace/>;
  }

  // modal
  const handleCloseModal = () => {
    setIsModalShown(false);
  };

  function handleShowModal(item) {
    if (item) {
      item.pictures.forEach((picture) => picture.modalId = uuidv4());
      setModalEvent(item);
    } else {
      initNewModalEvent();
    }
    setIsModalShown(true);
  };

  const handleSave = async () => {
    if (modalEvent.id) {
      updateEvent(modalEvent);
    } else {
      createEvent(modalEvent);
    }
    setIsModalShown(false);
  };

  // modal end
  function initNewModalEvent() {
    const item = {id: '', title: '', description: '', date: '', pictures: []};
    setModalEvent(item);
  }

  const handleChange = (e) => {
    setModalEvent({
      ...modalEvent,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeForPicture = async (e) => {
    const modalId = e.target.dataset.id;
    const index = modalEvent.pictures.findIndex((pic) => pic.modalId === modalId);
    const updatedModalEvent = {...modalEvent};

    if (e.target.name === 'picture') {
      const selectedFile = e.target.files[0];

      updatedModalEvent.pictures[index].picture = await pictureTransformService.convertPictureToBase64(selectedFile);
    } else {
      updatedModalEvent.pictures[index][e.target.name] = e.target.value;
    }

    // Use setModalEvent to update the state with the new object
    setModalEvent(updatedModalEvent);
  };

  const addPicture = () => {
    const newPicture = {
      modalId: `${uuidv4()}`,
      description: '',
      picture: '',
    };

    // Use the spread operator to create a new array with the added picture
    const updatedPictures = [...modalEvent.pictures, newPicture];

    setModalEvent({
      ...modalEvent,
      pictures: updatedPictures,
    });
  };

  const deleteEventPicture = async (modalId) => {
    const updatedModalEvent = {...modalEvent};

    updatedModalEvent.pictures = updatedModalEvent.pictures.filter((item) => item.modalId !== modalId);
    setModalEvent(updatedModalEvent);
  };

  async function createEvent(event) {
    await eventService.createEvent(event);
    getEvents();
  }

  async function updateEvent(event) {
    await eventService.updateEvent(event);
    getEvents();
  }

  async function deleteEvent(id) {
    await eventService.deleteMember(id);
    getEvents();
  }

  function getEvents() {
    eventService.getAllEvents().then((data) => {
      const sortedEvents = data.events.sort((a, b) => new Date(b.date) - new Date(a.date));
      setEvents(sortedEvents);
    } );
  }

  return (
    <div>
      <h1 className='mb-5'>Events bearbeiten
        <button onClick={() => handleShowModal()} type="button"
          className='btn bg-hoiboerzu-dark-One text-color-hoiboerzu-bright rounded-circle ml-5'>+</button>
      </h1>

      <div className='table'>
        <div className='row hoiboerzu-underline pb-2 mb-2'>
          <div className='col-12 col-md-2'>Titel</div>
          <div className='col-12 col-md-5'>Beschreibung</div>
          <div className='col-12 col-md-2'>Datum</div>
          <div className='col-12 col-md-1'>Bilder</div>
        </div>
        {events && events.length > 0 ? (
          events.map((item, index) => {
            return <div key={index} className='row hoiboerzu-underline pb-2 mb-2'>
              <div className='col-12 col-md-2'>{item.title}</div>
              <div className='col-12 col-md-5'>{item.description}</div>
              <div className='col-12 col-md-2'>{dateService.getFormattedDate(item.date)}</div>
              <div className='col-12 col-md-1'>{item.pictures.length > 0 ? (<div>{item.pictures.length}</div>) : (
                <div>0</div>)}</div>
              <div className='col-6 col-md-1'><FaEdit onClick={() => handleShowModal(item)}
                className='hoiboerzu-clickable'/></div>
              <div className='col-6 col-md-1'><FaTrash onClick={() => deleteEvent(item.id)}
                className='hoiboerzu-clickable'/></div>
            </div>;
          })
        ) : (<div>no Events found.</div>)}
      </div>

      <Modal show={isModalShown} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                name="title"
                placeholder="Enter title"
                value={modalEvent.title}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                as="textarea"
                name="description"
                placeholder="Enter description"
                value={modalEvent.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Date</Form.Label>
              <div>
                <input
                  required
                  className="rounded admin-event-datepicker-border"
                  type="date"
                  name="date"
                  value={dateService.getFormattedDateForDatepicker(modalEvent.date)}
                  onChange={handleChange}/>
              </div>
            </Form.Group>
          </Form>

          <Button onClick={() => addPicture()} className="mt-3"> + Foto</Button>

          {
            modalEvent.pictures && modalEvent.pictures.length > 0 ? (
              modalEvent.pictures.map((item, index) => {
                return <div key={item.modalId}>
                  <Separator/>


                  <Form.Group controlId="description">
                    <div className="d-flex justify-content-between">
                      <Form.Label>Description</Form.Label>
                      <FaTrash onClick={() => deleteEventPicture(item.modalId)}
                        className='hoiboerzu-clickable mt-2'/>
                    </div>
                    <Form.Control
                      placeholder="Enter description"
                      name="description"
                      maxLength="120"
                      value={item.description}
                      data-id={item.modalId}
                      onChange={handleChangeForPicture}
                    />
                  </Form.Group>
                  <Form.Group controlId="picture">
                    <Form.Label>Bild</Form.Label>
                    <Form.Control
                      type="file"
                      name="picture"
                      data-id={item.modalId}
                      placeholder="Enter image URL"
                      accept='image/png, image/gif, image/jpeg'
                      onChange={handleChangeForPicture}
                    />
                    <div>{item.picture &&
                      <img className='admin-event-image' src={`data:image/png;base64,${item.picture}`}
                        alt='Bild des Events'/>}</div>
                  </Form.Group>
                </div>;
              })
            ) : (<div></div>)
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminEvents;
