import AuthService from './authService';

const url = `${process.env.REACT_APP_API_CONNECTION_ROOT}president`;
const authService = new AuthService();

export default class PresidentService {
  getPresident() {
    return fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response.json();
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
  }

  setPresident(year, president) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authService.getToken()}`,
      },
      body: JSON.stringify({year, presidentId: president.id}),
    };

    return fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
        })
        .catch((error) => {
          throw error;
        });
  }
}
