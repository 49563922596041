import './App.css';
import React from 'react';
import Home from './pages/homepage/home';
import Members from './pages/memberpage/members';
import Contact from './pages/contactpage/contact';
import Events from './pages/eventspage/eventspage';
import Praesidenten from './pages/praesidentenpage/praesidenten';
import Login from './admin/login/login';
import {Route, Routes} from 'react-router-dom';
import NavBar from './components/navbar/navbar';
import Overview from './admin/overview/overview';
import AdminMembers from './admin/adminMembers/adminMembers';
import AdminEvents from './admin/adminEvents/adminEvents';
import AdminPresident from './admin/adminPresident/adminPresident';
require('bootstrap');

function App() {
  return (
    <>
      <head>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossOrigin="anonymous"></link>
        <link rel="stylesheet" href="./app.css"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta charSet="utf-8"/>
      </head>

      <body className='CommonStyles text-color-hoiboerzu-bright'>
        <div className="App">
          <NavBar/>

          <div className='AppContainer'>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="members" element={<Members/>}/>
              <Route path="contact" element={<Contact/>}/>
              <Route path="events" element={<Events/>}/>
              <Route path="presidents" element={<Praesidenten/>}/>

              <Route path="login" element={<Login/>}/>
              <Route path="admin" element={<Overview/>}/>
              <Route path="admin/members" element={<AdminMembers/>}/>
              <Route path="admin/events" element={<AdminEvents/>}/>
              <Route path="admin/president" element={<AdminPresident/>}/>
            </Routes>


          </div>
        </div>
      </body>
      <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossOrigin="anonymous"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
        integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
        crossOrigin="anonymous"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossOrigin="anonymous"></script>
    </>
  );
}

export default App;
