const url = `${process.env.REACT_APP_API_CONNECTION_ROOT}contact`;

export default class ContactService {
  requestContact(message) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    };

    return fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
          }
          return response;
        })
        .catch((error) => {
          throw error;
        });
  }
}

