import React, {useState} from 'react';
import {FaChevronLeft, FaChevronRight, FaCircle} from 'react-icons/fa';
import './pictureSlider.css';

function PictureSlider({pictures}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideStyles = {
    backgroundImage: `url('data:image/png;base64,${pictures[currentIndex].picture}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  };

  const dotStyles = (slideIndex) => {
    return currentIndex === slideIndex ? `white` : 'var(--background-color-dark-One)';
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? pictures.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === pictures.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const isSwitchingAvailable = () => {
    return pictures.length > 1;
  };

  return (
    <div className="image-slider-slider-styles">
      {
        isSwitchingAvailable() && (
          <>
            <div className="image-slider-left-arrow-styles" onClick={goToPrevious}><FaChevronLeft/></div>
            <div className="image-slider-right-arrow-styles" onClick={goToNext}><FaChevronRight/></div>
          </>
        )
      }

      <div className="image-slider-slide-styles" style={slideStyles}></div>

      {
        isSwitchingAvailable() && (
          <div className="image-slider-dot-container-styles">
            {
              pictures.map((slide, slideIndex) =>
                (<div className="image-slider-dot-styles" key={slideIndex} onClick={
                  () => goToSlide(slideIndex)}>
                  <FaCircle color={dotStyles(slideIndex)}/>
                </div>))
            }
          </div>
        )
      }

      <p>{pictures[currentIndex].description}</p>
    </div>
  );
}

export default PictureSlider;
