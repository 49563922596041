import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import ContactService from '../../services/contactService';
import {getHealth} from '../../services/healthCheckService';
import LoadingScreen from '../../components/loadingScreen/loadingsScreen';

const contactService = new ContactService();

function Contact() {
  const [loading, setLoading] = useState(false);

  const [contactRequest, setContactRequest] = useState({
    from: '',
    subject: '',
    text: '',
  });

  const [errors, setErrors] = useState({
    from: '',
    subject: '',
    text: '',
  });

  const [contactRequestSuccess, setcontactRequestSuccess] = useState({});

  useEffect(() => {
    setLoading(true);
    getHealth().then(() => {
      setLoading(false);
    });
  }, []);

  const handleChange = (e) => {
    setContactRequest({
      ...contactRequest,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: '',
    });

    setcontactRequestSuccess({});
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {...errors};

    if (!contactRequest.from.match(/^\S+@\S+\.\S+$/)) {
      newErrors.from = 'Bitte gib eine gültige Mail-Adresse ein';
      valid = false;
    }

    if (!contactRequest.subject.trim()) {
      newErrors.subject = 'Betreff ist erforderlich';
      valid = false;
    }

    if (!contactRequest.text.trim()) {
      newErrors.text = 'Nachricht ist erforderlich';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const clearContactRequest = () => {
    setContactRequest({
      from: '',
      subject: '',
      text: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await contactService.requestContact(contactRequest);
        clearContactRequest();
        setcontactRequestSuccess(true);
      } catch {
        setcontactRequestSuccess(false);
      }
    }
  };

  return (
    <div>
      <LoadingScreen active={loading}></LoadingScreen>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="from" className="mb-3">
          <div className="text-left">Mail-Adresse *</div>
          <Form.Control
            type="text"
            name="from"
            placeholder="Deine Mail-Adresse"
            value={contactRequest.from}
            onChange={handleChange}
          />
          {errors.from && <div className='hoiboerzu-error'>{errors.from}</div>}
        </Form.Group>

        <Form.Group controlId="subject" className="mb-3">
          <div className="text-left">Betreff *</div>
          <Form.Control
            type="text"
            name="subject"
            placeholder="Betreff"
            value={contactRequest.subject}
            onChange={handleChange}
          />
          {errors.subject && <div className='hoiboerzu-error'>{errors.subject}</div>}
        </Form.Group>

        <Form.Group controlId="text" className="mb-5">
          <div className="text-left">Um was es geht *</div>
          <Form.Control
            as="textarea"
            name="text"
            placeholder="Um was es geht"
            value={contactRequest.text}
            onChange={handleChange}
          />
          {errors.text && <div className='hoiboerzu-error'>{errors.text}</div>}
        </Form.Group>

        <Button type="submit" className="w-100 mb-3">
          Absenden
        </Button>

        {contactRequestSuccess === false &&
          <div className='hoiboerzu-error'>Deine Anfrage konnte nicht abgesendet werden, bitte probiere es
            nochmal.</div>}

        {contactRequestSuccess === true &&
          <div className='hoiboerzu-success'>Deine Anfrage wurde erfolgreich abgesendet!</div>}
      </Form>
    </div>
  );
}

export default Contact;
