import React, {useEffect, useState} from 'react';
import CardWithPictureLeft from '../../components/cards/cardWithPictureLeft/cardWithPictureLeft.js';
import CardWithPictureRight from '../../components/cards/cardWithPictureRight/cardWithPictureRight.js';
import MemberService from '../../services/memberService';
import LoadingScreen from '../../components/loadingScreen/loadingsScreen';

const memberService = new MemberService();

function Members() {
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState({});
  useEffect(() => {
    setLoading(true);
    memberService.getAllMembers().then((data) => {
      setMembers(data.members);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <LoadingScreen active={loading}></LoadingScreen>

      <div className='page-container'>
        <h1 className='mb-5'>Geschichte des Hoibörzuclub</h1>
        <h3 className='mb-4'>Im Winter 1844 an der internationalen Hoibörzu- Weltmeisterschaft von Japan wurde
                    der Hoibörzuclub gegründet.
                    Der Club hat bis heute die Generationen überlebt und besteht aus den folgenden Mitgliedern.
        </h3>
      </div>


      {members && members.length > 0 ? (
                members.map((item, index) => {
                  if (index % 2) {
                    return <CardWithPictureLeft key={index} cardTitle={item.title} cardDescription={item.description}
                      picture={item.picture}></CardWithPictureLeft>;
                  } else {
                    return <CardWithPictureRight key={index} cardTitle={item.title} cardDescription={item.description}
                      picture={item.picture}></CardWithPictureRight>;
                  }
                })
            ) : (<p>Keine Mitglieder gefunden</p>)}


    </div>
  );
}

export default Members;
