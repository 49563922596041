import React from 'react';

function Separator() {
  return (
    <div id="separator" className="w-100 border my-5">


    </div>
  );
}

export default Separator;
