import logo from '../../logo.png';
import instagramLinkPicture from '../../instagram-link-picture.png';
import './home.css';
import React from 'react';
import {getHealth} from '../../services/healthCheckService';


function Home() {
  getHealth();

  return (
    <div>

      <h1>Willkommen bei der Webseite vom Hoibörzuclub Beromünster</h1>

      <img src={logo} className="home-logo" alt="logo"/>
      <h3>
                Social Media
      </h3>

      <a href='https://www.instagram.com/hoiboerzuclubberomuenster/' target="_blank" rel="noreferrer noopener">
        <img src={instagramLinkPicture} alt="Instagram Account" width='100px'/>

        <p className='text-color-hoiboerzu-bright mt-2'>Instagram</p>
      </a>
    </div>
  );
}

export default Home;
