import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import MemberService from '../../services/memberService';
import PictureTransformService from '../../services/pictureTransformService';
import {FaEdit, FaTrash} from 'react-icons/fa';
import {Button, Form, Modal} from 'react-bootstrap';
import AuthService from '../../services/authService';
import './adminMembers.css';

const memberService = new MemberService();
const pictureTransformService = new PictureTransformService();
const authService = new AuthService();

function AdminMembers() {
  const token = authService.getToken();

  const [members, setMembers] = useState();
  useEffect(() => {
    memberService.getAllMembers().then((data) => {
      setMembers(data.members);
    },
    );
  }, []);

  const [isModalShown, setIsModalShown] = useState(false);
  const [modalMember, setModalMember] = useState({
    id: '',
    title: '',
    description: '',
    picture: null,
  });

  if (!token) {
    return <Navigate to="../../login" replace/>;
  }

  // modal
  const handleCloseModal = () => {
    setIsModalShown(false);
  };

  function handleShowModal(item) {
    if (item) {
      setModalMember(item);
    } else {
      initNewModalMember();
    }
    setIsModalShown(true);
  };

  const handleSave = async () => {
    if (modalMember.id) {
      updateMember(modalMember);
    } else {
      createMember(modalMember);
    }
    setIsModalShown(false);
  };
    // modal end

  // picture start
  const handleChangeForPicture = async (e) => {
    const selectedFile = e.target.files[0];
    const picture = await pictureTransformService.convertPictureToBase64(selectedFile);
    setModalMember({...modalMember, picture: picture});
  };

  // picture end

  function initNewModalMember() {
    const item = {id: '', title: '', description: '', picture: null};
    setModalMember(item);
  }

  const handleChange = (e) => {
    setModalMember({
      ...modalMember,
      [e.target.name]: e.target.value,
    });
  };

  async function createMember(member) {
    await memberService.createMember(member);
    getMembers();
  }

  async function updateMember(member) {
    await memberService.updateMember(member);
    getMembers();
  }

  async function deleteMember(memberId) {
    await memberService.deleteMember(memberId);
    getMembers();
  }

  function getMembers() {
    memberService.getAllMembers().then((data) => {
      setMembers(data.members);
    });
  }

  return (
    <div>
      <h1 className='mb-5'>Members bearbeiten
        <button onClick={() => handleShowModal()} type="button"
          className='btn bg-hoiboerzu-dark-One text-color-hoiboerzu-bright rounded-circle ml-5'>+</button>
      </h1>

      <div className='table'>
        <div className='row hoiboerzu-underline pb-2 mb-2'>
          <div className='col-12 col-md-2'>Titel</div>
          <div className='col-12 col-md-7'>Beschreibung</div>
          <div className='col-12 col-md-1'>Bild</div>
        </div>
        {members && members.length > 0 ? (
                    members.map((item, index) => {
                      return <div key={index} className='row hoiboerzu-underline pb-2 mb-2'>
                        <div className='col-12 col-md-2'>{item.title}</div>
                        <div className='col-12 col-md-7'>{item.description}</div>
                        <div className='col-12 col-md-1'>{item.picture ? (<div>yes</div>) : (<div>no</div>)}</div>
                        <div className='col-12 col-md-1'><FaEdit onClick={() => handleShowModal(item)}
                          className='hoiboerzu-clickable'/></div>
                        <div className='col-12 col-md-1'><FaTrash onClick={() => deleteMember(item.id)}
                          className='hoiboerzu-clickable'/></div>
                      </div>;
                    })
                ) : (<div>no Members found.</div>)}
      </div>

      <Modal show={isModalShown} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title"
                value={modalMember.title}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                placeholder="Enter description"
                value={modalMember.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="picture">
              <Form.Label>Bild</Form.Label>
              <Form.Control
                type="file"
                name="picture"
                placeholder="Enter image URL"
                accept='image/png, image/gif, image/jpeg'
                onChange={handleChangeForPicture}
              />
              <div>{modalMember.picture &&
                  <img className='admin-member-image' src={`data:image/png;base64,${modalMember.picture}`} alt='Bild eines Mitglieds'/>}</div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminMembers;
