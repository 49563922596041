import React, {useEffect, useState} from 'react';
import Event from '../../components/events/event';
import EventService from '../../services/eventService';
import LoadingScreen from '../../components/loadingScreen/loadingsScreen';

const eventService = new EventService();

function Events() {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState({});
  useEffect(() => {
    setLoading(true);
    eventService.getAllEvents().then((data) => {
      setEvents(data.events);
      setLoading(false);
    });
  }, []);


  const tabSettings = {Past: 'past', Future: 'future'};

  const [tab, setTab] = useState({});
  useEffect(() => {
    setTab(tabSettings.Past);
  }, []);

  const pastEventsBackgroundStyle = {
    background: `${tab === tabSettings.Past ? 'var(--background-color-dark-One)' : 'none'}`,
  };
  const futureEventsBackgroundStyle = {
    background: `${tab === tabSettings.Future ? 'var(--background-color-dark-One)' : 'none'}`,
  };

  return (
    <div>
      <LoadingScreen active={loading}></LoadingScreen>

      <h1 className='mb-5'>Events</h1>
      <h3 className='mb-4'>Was der Hoibörzuclub alles schon gemacht hat und was er in der nächsten Zeit noch machen
        wird.
      </h3>

      <div className="table">
        <div className="row">
          <div className="col-6 text-right border-right border-bottom pb-2 rounded-bottom hoiboerzu-clickable"
            style={pastEventsBackgroundStyle} onClick={() => setTab(tabSettings.Past)}>Vergangene Events
          </div>
          <div className="col-6 text-left border-left border-bottom pb-2 rounded-bottom hoiboerzu-clickable"
            style={futureEventsBackgroundStyle} onClick={() => setTab(tabSettings.Future)}>Bevorstehende Events
          </div>
        </div>
      </div>
      {
        tab && tab === tabSettings.Past ? (<div>{
            events && events.length > 0 ? (
              events
                  .filter((item) => new Date(item.date) < new Date())
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((item, index) => {
                    return <Event key={index}
                      title={item.title}
                      description={item.description}
                      date={item.date}
                      pictures={item.pictures}>
                    </Event>;
                  })
            ) : (
              <p>Keine vergangenen Events gefunden</p>
            )
        }</div>) :
          (<div>{
            events && events.length > 0 ? (
              events
                  .filter((item) => new Date(item.date) > new Date())
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((item, index) => {
                    return <Event key={index}
                      title={item.title}
                      description={item.description}
                      date={item.date}
                      pictures={item.pictures}>
                    </Event>;
                  })
            ) : (
              <p>Keine bevorstehenden Events gefunden</p>
            )
          }</div>)
      }


    </div>
  );
}

export default Events;
