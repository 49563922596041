import React, {useState} from 'react';
import logo from '../../logo.png';

import './navbar.css';

function NavBar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const navbar = (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-hoiboerzu-darker">
      <a className="navbar-brand" href="/#">
        <img id='navbarImage' src={logo} width='100px' height='100px'></img>
      </a>
      <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
        aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation"
        onClick={handleNavCollapse}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <a className="nav-item nav-link" href="/#">Home <span className="sr-only">(current)</span></a>
          <a className="nav-item nav-link" href="/members">Geschichte</a>
          <a className="nav-item nav-link" href="/events">Events</a>
          <a className="nav-item nav-link" href="/presidents">Präsidenten</a>
          <a className="nav-item nav-link" href="/contact">Kontakt</a>
        </div>
      </div>
    </nav>

  );

  return navbar;
}

export default NavBar;
