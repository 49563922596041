import React, {useState, useEffect} from 'react';

const CountdownTimer = ({targetDate}) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);


    return {days, hours, minutes, seconds};
  };

  const isTargetDateInTheFuture = () => {
    const now = new Date();
    return targetDate - now > 0;
  };

  const getDateOfGV = () => {
    const date = new Date(targetDate);
    return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='card-shadow bg-hoiboerzu-dark-One rounded p-4 m-4'>
      { isTargetDateInTheFuture() ? ` ${timeLeft.days} Tagen ${timeLeft.hours} Stunden ${timeLeft.minutes} Minuten und ${timeLeft.seconds} Sekunden` : `Die GV hat am ${getDateOfGV()} stattgefunden`}
    </div>
  );
};

export default CountdownTimer;
