import AuthService from '../../services/authService';
import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {Button, Dropdown} from 'react-bootstrap';
import MemberService from '../../services/memberService';
import PresidentService from '../../services/presidentService';
import GvDateService from '../../services/gvDateService';
import DateService from '../../services/dateService';

const authService = new AuthService();
const memberService = new MemberService();
const presidentService = new PresidentService();
const gvDateService = new GvDateService();
const dateService = new DateService();

function AdminPresident() {
  const token = authService.getToken();

  const [president, setPresident] = useState({
    id: '',
    presidentId: '',
    title: '',
  });
  useEffect(() => {
    getCurrentPresident();
  }, []);


  const [members, setMembers] = useState();
  useEffect(() => {
    memberService.getAllMembers().then((data) => {
      setMembers(data.members);
    },
    );
  }, []);

  const [gvDate, setGvDate] = useState();
  useEffect(() => {
    getGvDate();
  });

  const getGvDate = () => {
    gvDateService.getGvDate().then((data) => {
      const date = new Date(data.gvDate);
      setGvDate(dateService.getFormattedDateForDatepicker(date));
    });
  };

  if (!token) {
    return <Navigate to='../../login' replace/>;
  }

  const getCurrentPresident = () => {
    presidentService.getPresident().then((data) => {
      setPresident(data.president);
      setSelectedPresident(data.president);
      setYear(data.president.year);
    });
  };

  const [selectedPresident, setSelectedPresident] = useState();
  const [year, setYear] = useState();

  const getAvailableYears = () => {
    const today = new Date(Date.now());
    const year = today.getFullYear();

    const earlySeason = `${year - 1}/${year}`;
    const lateSeason = `${year}/${year + 1}`;
    return [earlySeason, lateSeason];
  };

  const saveNewPresident = async () => {
    await presidentService.setPresident(year, selectedPresident);
    getCurrentPresident();
  };

  const handleChange = async (e) => {
    await gvDateService.setGvDate(e.target.value);
    getGvDate();
  };

  return (
    <div>
      <h1 className='mb-5'>Präsident bearbeiten
      </h1>
      <Dropdown>
        <Dropdown.Toggle className="w-100" variant="success" id="dropdown-basic">
          {year}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100">
          {getAvailableYears() && getAvailableYears().length > 0 ? (
            getAvailableYears().map((item, index) => {
              return <Dropdown.Item key={index} onClick={() => setYear(item)}>{item}</Dropdown.Item>
              ;
            })
          ) : (<div>no Seasons found.</div>)}
        </Dropdown.Menu>
      </Dropdown>


      <Dropdown>
        <Dropdown.Toggle className="w-100" variant="success" id="dropdown-basic">
          {selectedPresident?.title}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100">
          {members && members.length > 0 ? (
            members.map((item, index) => {
              return <Dropdown.Item key={index} onClick={() => setSelectedPresident(item)}>{item.title}</Dropdown.Item>
              ;
            })
          ) : (<div>no Members found.</div>)}
        </Dropdown.Menu>
      </Dropdown>

      {selectedPresident && president && (president.title !== selectedPresident?.title || president.year !== year ) &&
        <Button className="mt-4" onClick={() => saveNewPresident()}>Neuer Präsident speichern</Button>}

      <h1 className="mt-5">GV-Datum bearbeiten</h1>
      <input type="date" value={gvDate} onChange={handleChange}/>
    </div>
  );
}

export default AdminPresident;
