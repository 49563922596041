export default class PictureTransformService {
  async convertPictureToBase64(picture) {
    return new Promise((resolve, reject) => {
      if (picture) {
        if (picture instanceof Blob) {
          this.convertToBase64(picture, resolve, reject);
        } else {
          resolve(picture);
        }
      } else {
        resolve(null);
      }
    });
  }

  convertToBase64(picture, resolve, reject) {
    const reader = new FileReader();
    reader.readAsDataURL(picture);
    reader.onloadend = () => {
      const base64Image = reader.result.split(',')[1];
      resolve(base64Image);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  }
}
